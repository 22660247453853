import { useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "hooks/use-translation";
import Input from "components/input";
import Button from "components/button";
import PasswordInput from "components/password-input";
import { MainWrapper } from "components/main-wrapper";

const { REACT_APP_SSO_DOMAIN } = process.env;

export function Login() {
  const { translate, tcommon, tpage } = useTranslation({
    page: "login",
  });

  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );

  const state = useMemo(() => {
    return searchParams
      .get("state")
      ?.split(",")
      .map((x) => x.split("|"))
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
  }, [window.location.search]);

  const getError = () => {
    const urlError = searchParams.get("error");
    if (urlError == undefined) return "";
    return decodeURI(urlError);
  };

  const [fields, setFields] = useState({
    username: searchParams.get("email") ?? "",
    password: "",
    device: "",
  });

  const [error] = useState(getError());

  useEffect(() => {
    if (state) {
      setFields({ ...fields, device: state["device"] });
    }
    if (window.location.pathname === "/login") {
      window.localStorage.setItem("loginSearchParams", window.location.search);
    }
  }, []);

  return (
    <MainWrapper>
      <div className="w-full space-y-4">
        <p className="font-semibold text-xs text-center">
          {tpage("login-with-sso")}
        </p>

        <hr className="border-dashed" />

        <form
          action={`${REACT_APP_SSO_DOMAIN}/login${window.location.search}`}
          method="POST"
          encType="multipart/form-data"
          className="space-y-4"
        >
          <Input
            label={tpage("email-address")}
            id="email"
            name="email"
            type="email"
            placeholder="new.user@givenergy.co.uk"
            value={fields.username}
            onChange={(event) =>
              setFields({ ...fields, username: event.target.value })
            }
            required
          />

          <PasswordInput
            label={tcommon("password")}
            id="password"
            name="password"
            value={fields.password}
            onChange={(event) =>
              setFields({ ...fields, password: event.target.value })
            }
            required
          />

          <input type="hidden" name="device" value={fields.device} />
          {error && (
            <p className="max-w-full text-sm text-danger-500">
              <Trans
                t={translate}
                i18nKey={"page.login.invalid-credentials-cta"}
              />
            </p>
          )}

          <hr className="border-dashed" />

          <Button type="submit">{tpage("login")}</Button>
        </form>

        <div className="flex flex-row items-center justify-between gap-4">
          <a
            className="underline text-[.5rem] text-primary-muted"
            href="/forgot-password"
          >
            {tpage("recover-cta")}
          </a>

          <a
            className="underline text-[.5rem] text-primary-muted"
            href="/register"
          >
            {tpage("register-cta")}
          </a>
        </div>
      </div>
    </MainWrapper>
  );
}
