import { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const Input = forwardRef(function Input(
  { label, className, labelClassName, inputClassName, type = "text", ...rest },
  ref
) {
  return (
    <>
      <label
        className={clsx(
          "group w-full flex flex-col gap-x-2 gap-y-2 xs:items-left xs:flex-col",
          className
        )}
      >
        {label && (
          <span
            className={clsx(
              labelClassName
                ? labelClassName
                : "text-sm text-primary-muted xs:min-w-24 group-focus-within:text-brand-500"
            )}
          >
            {label}
          </span>
        )}
        <input
          {...rest}
          type={type}
          ref={ref}
          className={clsx(
            inputClassName
              ? inputClassName
              : "px-3 py-2 text-sm rounded-lg shadow-inner border-0 ring-1 ring-slate-200 focus:ring-brand-400 placeholder:text-primary-muted"
          )}
        />
      </label>
    </>
  );
});

Input.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  type: PropTypes.string,
};

export default Input;
