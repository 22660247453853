import { useEffect, useState } from "react";

export function usePasswordCriteria (password) {
  const [validation, setValidation] = useState({
    hasCapitalLetter: false,
    hasNumber: false,
    hasSymbol: false,
    hasLength: false
  });

  useEffect(() => {
    const _password = password ?? "";
    
    setValidation({
      hasCapitalLetter: /[A-Z]/.test(_password),
      hasNumber: /[0-9]/.test(_password),
      hasSymbol: /[^a-z0-9]/i.test(_password),
      hasLength: /^.{8,}$/.test(_password)
    });
  }, [password]);

  return { ...validation };
}