import { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PasswordInput = forwardRef(function PasswordInput(
  { label, className, labelClassName, inputClassName, ...rest },
  ref
) {
  const [visible, setVisible] = useState(false);

  return (
    <label
      className={clsx(
        "group sw-full flex flex-col gap-2 xs:items-left xs:flex-col",
        className
      )}
    >
      {label && (
        <span
          className={clsx(
            labelClassName
              ? labelClassName
              : "text-sm text-primary-muted xs:min-w-24 shrink-0 group-focus-within:text-brand-500"
          )}
        >
          {label}
        </span>
      )}
      <div className="relative w-full min-w-0">
        <input
          {...rest}
          type={visible ? "text" : "password"}
          ref={ref}
          className={clsx(
            inputClassName
              ? inputClassName
              : "w-full py-2 pl-3 pr-10 text-sm border-0 rounded-lg ring-1 ring-slate-200 shadow-inner focus:ring-brand-400"
          )}
        />
        <button
          className="absolute -translate-y-1/2 top-1/2 right-2 text-primary-muted"
          type="button"
          onClick={() => setVisible(!visible)}
        >
          {visible ? (
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.25 12C19.25 13 17.5 18.25 12 18.25C6.5 18.25 4.75 13 4.75 12C4.75 11 6.5 5.75 12 5.75C17.5 5.75 19.25 11 19.25 12Z"
              ></path>
              <circle
                cx="12"
                cy="12"
                r="2.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              ></circle>
            </svg>
          ) : (
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M18.6247 10C19.0646 10.8986 19.25 11.6745 19.25 12C19.25 13 17.5 18.25 12 18.25C11.2686 18.25 10.6035 18.1572 10 17.9938M7 16.2686C5.36209 14.6693 4.75 12.5914 4.75 12C4.75 11 6.5 5.75 12 5.75C13.7947 5.75 15.1901 6.30902 16.2558 7.09698"
              ></path>
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.25 4.75L4.75 19.25"
              ></path>
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10.409 13.591C9.53033 12.7123 9.53033 11.2877 10.409 10.409C11.2877 9.5303 12.7123 9.5303 13.591 10.409"
              ></path>
            </svg>
          )}
        </button>
      </div>
    </label>
  );
});

PasswordInput.propTypes = {
  label: PropTypes.node,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

export default PasswordInput;
