import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import {
  Login,
  DeleteAccountSteps,
  Register,
  ForgotPassword,
  ResetPassword,
  PortalLogin,
} from "pages";

import { useTheme } from "@givenergy/tailwind-theming";

function App() {
  const [, { set: setTheme }] = useTheme();

  useEffect(() => {
    setTheme("givenergy");
  }, []);

  return (
    <Routes>
      <Route path="/login" element={Login()} />
      <Route path="/portal-login" element={PortalLogin()} />
      <Route path="/forgot-password" element={ForgotPassword()} />
      <Route path="/reset-password" element={ResetPassword()} />
      <Route path="/register" element={Register()} />
      <Route path="/delete-steps" element={DeleteAccountSteps()} />
    </Routes>
  );
}

export default App;
