import React, { useEffect, useState } from "react";

import { useTranslation } from "hooks/use-translation";
import { MainWrapper } from "components/main-wrapper";

export const DeleteAccountSteps = () => {
  const [url, setURL] = useState();
  const { tpage } = useTranslation({
    page: "delete-account-steps",
  });

  useEffect(() => {
    setURL(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? "cloud.givenergy.installer://"
        : ""
    );
  }, []);

  return (
    <MainWrapper>
      <details className="space-y-3">
        <summary className="text-2xl">{tpage("app-name")}</summary>
        <div className="flex flex-row gap-2">
          <p>1. </p>
          <p>
            {tpage("open")}{" "}
            <a className="text-[#50B9A9]" href={url}>
              {tpage("app-name")}
            </a>{" "}
            {tpage("on-your-device")}
          </p>
        </div>
        <div className="flex flex-row gap-2">
          <p>2. </p>
          <p>{tpage("login")}</p>
        </div>
        <div className="flex flex-row gap-2">
          <p>3.</p>
          <p>{tpage("successful-login")}</p>
        </div>
        <div className="flex flex-row gap-2">
          <p>4.</p>
          <p>{tpage("user-icon")}</p>
        </div>
        <div className="flex flex-row gap-2">
          <p>5.</p>
          <p>{tpage("user-icon-pressed")}</p>
        </div>
        <div className="flex flex-row gap-2">
          <p>6.</p>
          <p>{tpage("delete-account")}</p>
        </div>
      </details>
      <details className="space-y-3">
        <summary className="text-2xl">{tpage("portal-name")}</summary>
        <p>{tpage("tba")}</p>
      </details>
      <details className="space-y-3">
        <summary className="text-2xl">{tpage("consumer-app-name")}</summary>
        <p>{tpage("tba")}</p>
      </details>
    </MainWrapper>
  );
};
