import Button from "components/button";
import Input from "components/input";
import PasswordInput from "components/password-input";
import { PortalWrapper } from "components/portal-wrapper";
import { useTranslation } from "hooks/use-translation";
import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

const { REACT_APP_SSO_DOMAIN } = process.env;

const commonsStyles = {
  label: "text-xs text-primary-200",
  input:
    "w-full p-3 text-sm rounded-lg shadow-inner placeholder:text-primary-muted",
};

export function PortalLogin() {
  const { tpage } = useTranslation({
    page: "portal-login",
  });

  const [fields, setFields] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState("");

  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search]
  );

  const getError = () => {
    const urlError = searchParams.get("error");
    return urlError ? decodeURI(urlError) : "";
  };

  const errorSetter = () => {
    const errorMessage = getError();

    if (errorMessage && errorMessage.includes("username")) {
      return setError(tpage("credentials-error"));
    }

    if (errorMessage) {
      return setError(tpage("generic-error"));
    }
  };

  useEffect(() => {
    errorSetter();
  }, [searchParams]);

  return (
    <PortalWrapper header={tpage("connect-account")}>
      <form
        action={`${REACT_APP_SSO_DOMAIN}/portal-login${window.location.search}`}
        method="POST"
        encType="multipart/form-data"
        className="w-full flex flex-col space-y-4 justify-start"
      >
        <p className={clsx(commonsStyles.label, "font-bold")}>
          {tpage("description")}
        </p>

        <Input
          label={tpage("username")}
          id="username"
          name="username"
          type="username"
          placeholder={tpage("username")}
          value={fields.username}
          labelClassName={commonsStyles.label}
          inputClassName={commonsStyles.input}
          className="gap-y-1"
          onChange={(event) => {
            setFields((currV) => ({ ...currV, username: event.target.value }));
          }}
          required
        />

        <PasswordInput
          label={tpage("password")}
          id="password"
          name="password"
          placeholder={tpage("password")}
          value={fields.password}
          labelClassName={commonsStyles.label}
          inputClassName={commonsStyles.input}
          className="gap-y-1"
          onChange={(event) => {
            setFields((currV) => ({ ...currV, password: event.target.value }));
          }}
          required
        />

        {error && <p className="text-danger-600 font-bold">{error}</p>}

        <Button
          className="w-full flex justify-center items-center bg-[#233c7b] rounded-xl text-primary-0 font-bold text-xl p-3"
          type="submit"
        >
          {tpage("authenticate")}
        </Button>
      </form>
    </PortalWrapper>
  );
}
