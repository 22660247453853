import { React } from "react";
import PropTypes from "prop-types";

export function PortalWrapper({ children, header }) {
  return (
    <div className="flex justify-center items-center h-full w-full lg:bg-lg-portal-bg bg-sm-portal-bg bg-cover">
      <div className="w-full max-w-xl flex flex-col justify-center items-center space-y-4 bg-[#233c7b80] shadow-[0px_4px_4px_0px_rgba(27,87,131,0.04)] mx-5 p-3 rounded-xl">
        <h1 className="text-primary-0 font-bold">{header}</h1>
        <hr className="border-dashed w-full border-primary-400" />
        {children}
      </div>
    </div>
  );
}

PortalWrapper.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
};
