/* eslint-disable sonarjs/no-duplicate-string */
import PropTypes from "prop-types";
import clsx from "clsx";

import { usePasswordCriteria } from "hooks/use-password-criteria";
import { useTranslation } from "hooks/use-translation";

export default function PasswordCriteria({ password }) {
  const validation = usePasswordCriteria(password);
  const { tcomponent } = useTranslation({ component: "password-criteria" });

  return (
    <div>
      <p className="text-sm text-primary-muted">
        <span>{tcomponent("must-have")}</span>{" "}
        <span
          className={clsx(
            validation.hasCapitalLetter ? "text-success-500" : "text-danger-500"
          )}
        >
          {tcomponent("capital-letter")}
        </span>
        {tcomponent("a")}
        <span
          className={clsx(
            validation.hasNumber ? "text-success-500" : "text-danger-500"
          )}
        >
          {tcomponent("number")}
        </span>
        {tcomponent("and-a")}
        <span
          className={clsx(
            validation.hasSymbol ? "text-success-500" : "text-danger-500"
          )}
        >
          {tcomponent("symbol")}
        </span>
        {". "}
        <span>{tcomponent("must-also-be-at-least")}</span>{" "}
        <span
          className={clsx(
            validation.hasLength ? "text-success-500" : "text-danger-500"
          )}
        >
          {tcomponent("eight-characters")}
        </span>
        {"."}
      </p>
    </div>
  );
}

PasswordCriteria.propTypes = {
  password: PropTypes.string,
};
