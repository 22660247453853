import { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const Button = forwardRef(function Button(
  { children, type = "button", className, ...rest },
  ref
) {
  return (
    <button
      {...rest}
      ref={ref}
      type={type}
      className={clsx(
        className
          ? className
          : "w-full px-3 py-2 transition-colors rounded-xl ring-1 ring-gray-200 hover:bg-gray-100 shadow-md font-light"
      )}
    >
      {children}
    </button>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(["button", "submit"]),
  className: PropTypes.string,
};

export default Button;
