import { useEffect } from "react";

export function usePasswordCompare(refs, fields) {
  useEffect(() => {
    if (!refs?.passwordConfirm?.current) {
      return;
    }

    refs.passwordConfirm.current.setCustomValidity(
      fields.password !== fields.confirmedPassword
        ? "Please check that the passwords match."
        : ""
    );
  }, [fields.password, fields.confirmedPassword]);
}
