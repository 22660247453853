import { useRef, useState } from "react";
import clsx from "clsx";
import { usePasswordCompare } from "hooks/use-password-compare";
import { useTranslation } from "hooks/use-translation";
import PasswordCriteria from "components/password-criteria";
import PasswordInput from "components/password-input";
import Input from "components/input";
import Button from "components/button";
import { MainWrapper } from "components/main-wrapper";
const { REACT_APP_SSO_DOMAIN } = process.env;

export function Register() {
  const { tpage, tcommon } = useTranslation({
    page: "register",
  });

  const [fields, setFields] = useState({
    email: "",
    password: "",
    confirmedPassword: "",
    code: "",
  });

  const [error, setError] = useState(false);
  const [emailConfirmError, setEmailConfirmError] = useState(false);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [finished, setFinished] = useState(false);
  const passwordConfirm = useRef(null);

  const register = async (event) => {
    event.preventDefault();
    const response = await fetch(
      `${REACT_APP_SSO_DOMAIN}/register${window.location.search}`,
      {
        method: "POST",
        body: JSON.stringify(fields),
      }
    );
    setError(response.ok == false);
    setNeedsConfirmation(response.ok);
  };

  const handleConfirmEmail = async (event) => {
    event.preventDefault();
    const response = await fetch(
      `${REACT_APP_SSO_DOMAIN}/confirm${window.location.search}`,
      {
        method: "POST",
        body: JSON.stringify(fields),
      }
    );
    setEmailConfirmError(response.ok == false);
    setFinished(response.ok);
  };

  const goToLogin = async (event) => {
    event.preventDefault();
    location.href = `/login${
      window.localStorage.getItem("loginSearchParams") ?? ""
    }`;
  };

  usePasswordCompare({ passwordConfirm }, fields);

  return (
    <MainWrapper>
      <form onSubmit={register} className="space-y-2">
        {needsConfirmation && finished == false && (
          <div className="text-sm text-center">
            {tpage("confirmation")}: {fields.email}
          </div>
        )}

        {finished && <p>{tpage("completed")}</p>}

        <Input
          className={clsx(needsConfirmation && "hidden")}
          label={tcommon("email")}
          id="email"
          type="email"
          name="email"
          value={fields.email}
          placeholder="new.user@givenergy.co.uk"
          onChange={(event) =>
            setFields({ ...fields, email: event.target.value })
          }
          required
        />

        {needsConfirmation && finished === false && (
          <>
            <Input
              label={tcommon("code")}
              id="code"
              name="code"
              value={fields.code}
              onChange={(event) =>
                setFields({ ...fields, code: event.target.value })
              }
              required
            />
            {emailConfirmError && (
              <p className="max-w-full text-sm text-danger-500">
                {tpage("invalid-code")}
              </p>
            )}
          </>
        )}

        {!needsConfirmation && finished === false && (
          <>
            <PasswordInput
              className="xs:!mt-4"
              label={tcommon("password")}
              id="password"
              name="password"
              value={fields.password}
              placeholder="Password"
              onChange={(event) =>
                setFields({ ...fields, password: event.target.value })
              }
              required
            />

            <PasswordInput
              ref={passwordConfirm}
              label={tcommon("confirm")}
              id="password-confirm"
              name="confirmedPassword"
              value={fields.confirmedPassword}
              placeholder="Confirm Password"
              onChange={(event) =>
                setFields({ ...fields, confirmedPassword: event.target.value })
              }
              required
            />

            {error === false && <PasswordCriteria password={fields.password} />}

            {error && (
              <p className="max-w-full text-sm text-danger-500">
                {tpage("error")}
              </p>
            )}
          </>
        )}

        {finished && (
          <Button onClick={goToLogin}>{tpage("go-to-login")}</Button>
        )}

        {finished === false && (
          <div className="flex justify-end">
            {needsConfirmation ? (
              <Button onClick={handleConfirmEmail}>{tcommon("confirm")}</Button>
            ) : (
              <Button type="submit">{tpage("sign-up")}</Button>
            )}
          </div>
        )}
      </form>

      {!needsConfirmation && (
        <div className="flex justify-center gap-1 text-sm">
          <p>{tpage("login-cta")}</p>
          <a
            className="underline"
            href={`/login${
              window.localStorage.getItem("loginSearchParams") ?? ""
            }`}
          >
            {tpage("login")}
          </a>
        </div>
      )}
    </MainWrapper>
  );
}
