import { useState } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "hooks/use-translation";

import Input from "components/input";
import Button from "components/button";
import { MainWrapper } from "components/main-wrapper";

const { REACT_APP_SSO_DOMAIN } = process.env;

export function ForgotPassword() {
  const { tpage, tcommon, translate } = useTranslation({
    page: "forgot-password",
  });

  const [fields, setFields] = useState({
    username: "",
  });

  const [error, setError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setError(false);

    const res = await fetch(`${REACT_APP_SSO_DOMAIN}/forgotten`, {
      method: "POST",
      body: JSON.stringify({ email: fields.username }),
    });

    if (!res.ok) {
      setError(true);
      return;
    }

    window.location = `/reset-password?email=${fields.username}`;
  };

  return (
    <MainWrapper>
      <form className="space-y-2" onSubmit={handleSubmit}>
        <Input
          label={tcommon("email")}
          id="email"
          type="email"
          value={fields.username}
          onChange={(event) =>
            setFields({ ...fields, username: event.target.value })
          }
          required
        />

        {error && (
          <p className="max-w-full text-sm text-danger-500">
            <Trans t={translate} i18nKey={"page.forgot-password.retry.cta"} />
          </p>
        )}

        <div className="flex justify-end">
          <Button type="submit">{tpage("send-code")}</Button>
        </div>
      </form>

      <div className="text-sm text-center">
        <a
          className="underline"
          href={`/login${
            window.localStorage.getItem("loginSearchParams") ?? ""
          }`}
        >
          {tpage("return-to-login")}
        </a>
      </div>
    </MainWrapper>
  );
}
